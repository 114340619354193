/**
 *  路由文件
 * keepAlive: true 代表需要做页面缓存
 */

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { title: "首页" },
  },
  // 这是测试调试页面
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("../views/test/test.vue"),
  //   meta: { title: "Test" },
  // },
  // 1.以下是往届服务路由
  {
    path: "/previousservice",
    name: "previousservice",
    component: () => import("../views/previousservice/index.vue"),
    meta: { title: "往届服务", keepAlive: true },
  },
  {
    path: "/previousservice/addservice",
    name: "addpreviousservice",
    component: () => import("../views/previousservice/addservice.vue"),
    meta: { title: "添加服务" },
  },
  // 2.以下是清华二代子女调查表路由
  {
    path: "/questionnaire",
    name: "questionnaire",
    component: () => import("../views/questionnaire"),
    meta: { title: "清华经管EMBA二代子女成长教育与职业规划" },
  },
  {
    path: "/questionnaire/login",
    name: "QuestionnaireLogin",
    component: () => import("../views/questionnaire/login.vue"),
    meta: { title: "本人信息" },
  },
  {
    path: "/questionnaire/child",
    name: "QuestionnaireChild",
    component: () => import("../views/questionnaire/child.vue"),
    meta: { title: "子女信息" },
  },

  {
    path: "/questionnaire/commitinfo",
    name: "QuestionnaireCommiInfo",
    component: () => import("../views/questionnaire/commitbaseinfo.vue"),
    meta: { title: "调研信息" },
  },
  {
    path: "/questionnaire/childdetail",
    name: "QuestionnaireChildDetail",
    component: () => import("../views/questionnaire/commitdetailinfo.vue"),
    meta: { title: "调研信息" },
  },
  // 3.以下是校园艺术节路由
  {
    path: "/art/allrank",
    name: "singallrank",
    component: () => import("../views/artholiday/music/allrank.vue"),
    meta: { title: "歌手大赛总排名" },
  },
  {
    path: "/art/grouprank",
    name: "singgrouprank",
    component: () => import("../views/artholiday/music/grouprank.vue"),
    meta: { title: "歌手大赛小组分数" },
  },
  {
    path: "/art/vote",
    name: "singvote",
    component: () => import("../views/artholiday/music/vote.vue"),
    meta: { title: "歌手大赛投票" },
  },
  {
    path: "/art/paint",
    name: "drawphoto",
    component: () => import("../views/artholiday/drawphoto/index.vue"),
    meta: { title: "绘画和摄影大赛", keepAlive: true },
  },
  {
    path: "/art/detail",
    name: "artdetail",
    component: () => import("../views/artholiday/drawphoto/detail.vue"),
    meta: { title: "绘画和摄影大赛投票" },
  },
  // 4.以下是批改作业
  {
    path: "/homework",
    name: "homework",
    component: () => import("../views/homework/homework.vue"),
    meta: { title: "培诺-AI" },
  },
];
const router = new VueRouter({
  routes,
});
export default router;
