// 按需全局引入 vant组件
import Vue from "vue";
import {
  Button,
  List,
  Cell,
  Tag,
  CellGroup,
  Tabbar,
  TabbarItem,
  Form,
  Field,
  DatetimePicker,
  Picker,
  Popup,
  Search,
  Toast,
  PullRefresh,
  Dialog,
  Tab,
  Tabs,
  Icon,
  Checkbox,
  Uploader,
  CheckboxGroup,
} from "vant";
import { Image as VanImage } from "vant";
Vue.use(Button);
Vue.use(Cell);
Vue.use(Tag);
Vue.use(CellGroup);
Vue.use(List);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Toast);
Vue.use(PullRefresh);
Vue.use(Dialog);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(Uploader);
Vue.use(CheckboxGroup);
